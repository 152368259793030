
import anime from 'animejs/lib/anime.es.js';


////


let optionsHowItWorks = {
    rootMargin: "0px",
    threshold: 0.6,
};

let anime_HowItWorks = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            anime({
                targets: '.how-it-works-item-head__number',
                scale: {
                    value : [0.5, 1],
                    easing: 'easeInOutCirc',
                },
                translateX: (el, index) => {
                    let value = 45
                    return [value, 0]
                }, 
                opacity: {
                    value: [0, 1],
                    //duration: 1700,
                },

                borderRadius: {
                    value: ['10%', '1.25rem'],
                    duration: 700,
                },
                rotate: {
                    value: [90, 0],
                    easing: 'easeInOutBack',
                },
                //rotate: [ anime.stagger(30, {start:-30}), 1],
                delay: anime.stagger(600),
                duration: 1000,
                easing: 'easeInOutCirc',
            });

            anime({
                targets: '.how-it-works-item-head h3',
                scale: {
                    value : [0.5, 1],
                    easing: 'easeInOutCirc',
                },
                translateX: (el, index) => {
                    let value = -70
                    return [value, 0]
                }, 
                opacity: {
                    value: [0, 1],
                    //duration: 700,
                },
                delay: anime.stagger(600, {start:50}),
                duration: 1000,
                easing: 'easeInOutCirc',
            });

            anime({
                targets: '.how-it-works-item h5',
                opacity: {
                    value: [0, 1],
                    //duration: 700,
                },
                delay: anime.stagger(600, {start:600}),
                duration: 2000,
                easing: 'easeOutQuad',
            });

            observer.unobserve(entry.target);
        }
    });
};


let observer_HowItWorks = new IntersectionObserver(anime_HowItWorks, optionsHowItWorks);
let HowItWorks = document.querySelector(".how-it-works--wrap");
observer_HowItWorks.observe(HowItWorks);



////



let optionsWhats = {
    rootMargin: "0px",
    threshold: 0.8,
};


const targets = document.querySelectorAll('.whats-in-column p');
let pPadding = 1.5*16;
let pHight = targets[0].clientHeight + pPadding;
console.log(pHight);

let anime_WhatsCreator = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            anime({
                targets: '.whats-in-column.whats-in--creators p',
                translateY: (el, index) => {
                    let value = -index*pHight
                    return [value, 0]
                },                
                scale: {
                    value : [0.5, 1],
                    easing: 'easeInOutBack',
                    duration: 600,
                },
                opacity: {
                    value: [0, 1],
                    //duration: 700,
                },
                
                delay: anime.stagger(100),
                duration: 500,
                easing: 'easeOutCirc',
            });

           observer.unobserve(entry.target);
        }
    });  
};

let anime_WhatsBrand = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            anime({
                targets: '.whats-in-column.whats-in--brand p',
                translateY: (el, index) => {
                    let value = -index*pHight
                    return [value, 0]
                },
                opacity: 1,
                
                delay: anime.stagger(60, {start: 300}),
                duration: 400,
                easing: 'easeInOutBack',
            });

           observer.unobserve(entry.target);
        }
    });  
};


  
let observerWhatsCreator = new IntersectionObserver(anime_WhatsCreator, optionsWhats);
let creatorList = document.querySelector(".whats-in-column.whats-in--creators");
observerWhatsCreator.observe(creatorList);


let observerWhatsBrand = new IntersectionObserver(anime_WhatsBrand, optionsWhats);
let brandList = document.querySelector(".whats-in-column.whats-in--brand");
observerWhatsBrand.observe(brandList);



console.log('end');